import React from "react";
import { useTranslation } from "react-i18next";
import a1 from "../../Assets/Images/a1.png";
import b1 from "../../Assets/Images/b1.png";
import d1 from "../../Assets/Images/d1.png";
import L1 from "../../Assets/Images/L1.png";
import L2 from "../../Assets/Images/L2.png";
import landd1 from "../../Assets/Images/landd1.png";
import clock from "../../Assets/Images/clock.png";
import GOH from "../../Assets/Images/GOH.png";
import geo from "../../Assets/Images/geo.png";
import Devair from "../../Assets/Images/Devair.png";
import Damir from "../../Assets/Images/Damir.png";
import BBG from "../../Assets/Images/BBG.png";
import Brian from "../../Assets/Images/Brian.png";
import Nicholas from "../../Assets/Images/Nicholas.png";
import Dina from "../../Assets/Images/Dina.png";
import Viacheslav from "../../Assets/Images/Viacheslav.png";
import Ilkhom from "../../Assets/Images/Ilkhom.png";
import radicle from "../../Assets/Images/radicle.png";
import Abouttabs from "../../Components/abouttabs";
import Innerhero from "../../Components/innerbanner";
import unilayer from "../../Assets/Images/unilayer.png";
import polygon from "../../Assets/Images/polygon.png";
import au21 from "../../Assets/Images/au21.png";
import mexc from "../../Assets/Images/mexc.png";
import radicleg from "../../Assets/Images/radicle-gaming.png";
import partner1 from "../../Assets/Images/partner1.png";
import partner2 from "../../Assets/Images/partner2.png";
import partner3 from "../../Assets/Images/partner3.png";
import partner4 from "../../Assets/Images/partner4.png";
import partner5 from "../../Assets/Images/partner5.png";
import partner6 from "../../Assets/Images/partner6.png";
import partner7 from "../../Assets/Images/partner7.png";
import partner8 from "../../Assets/Images/partner8.png";
import partner9 from "../../Assets/Images/partner9.png";
import partner10 from "../../Assets/Images/partner10.png";
import partner11 from "../../Assets/Images/partner11.png";
import partner12 from "../../Assets/Images/partner12.png";
import partner13 from "../../Assets/Images/partner13.png";
import partner14 from "../../Assets/Images/partner14.png";
import partner15 from "../../Assets/Images/partner15.png";
import partner16 from "../../Assets/Images/partner16.png";
import partner17 from "../../Assets/Images/partner17.png";
import partner18 from "../../Assets/Images/partner18.png";
import partner19 from "../../Assets/Images/partner19.png";
import partner20 from "../../Assets/Images/partner20.png";
import partner21 from "../../Assets/Images/partner21.png";
import partner22 from "../../Assets/Images/partner22.png";
import partner23 from "../../Assets/Images/partner23.png";
import partner24 from "../../Assets/Images/partner24.png";
import partner25 from "../../Assets/Images/partner25.png";
import partner26 from "../../Assets/Images/partner26.png";
import partner27 from "../../Assets/Images/partner27.png";
import partner28 from "../../Assets/Images/partner28.png";
import partner29 from "../../Assets/Images/partner29.png";
import partner30 from "../../Assets/Images/partner30.png";
import partner31 from "../../Assets/Images/partner31.png";
import Girand from "../../Assets/Images/Girand.png";
import GaiaFund from "../../Assets/Images/GaiaFund.png";
import Chianplay from "../../Assets/Images/Chianplay.png";

const About = () => {
  const { t } = useTranslation();
  return (
    <div id="mainwrapper" className="aboutpage ">
      <Innerhero />
      <Abouttabs />

      <section className="playsect aboutplay">
        <div className="container">
          <div className="columns2">
            <div className="col plybxes abtplay">
              <img src={b1} alt="" />
              <h3>PVP</h3>
              <p>Create your team and go to war to earn as much $GG possible</p>
            </div>
            <div className="col plybxes abtplay">
              <img src={L1} alt="" />
              <h3>Legion mode</h3>
              <p>
                Fight alone or join a clan and fight for power in legion mode
              </p>
            </div>
            <div className="col plybxes abtplay">
              <img src={d1} alt="" />
              <h3>adventure mode</h3>
              <p>
                A full blown MMORPG adventure game full of quests and wars to be
                won
              </p>
            </div>
            <div className="col plybxes abtplay">
              <img src={a1} alt="" />
              <h3>Marketplace</h3>
              <p>Buy and sell your in-game items</p>
            </div>
            <div className="col plybxes abtplay">
              <img src={landd1} alt="" />
              <h3>gaia land</h3>
              <p>Own property on the safezone in Gaia Everworld</p>
            </div>
            <div className="col plybxes abtplay">
              <img src={L2} alt="" />
              <h3>gaia handbook</h3>
              <p>Learn everything about GAIA in our constant upload handbook</p>
            </div>
          </div>
        </div>
        <a
          href="https://docs.gaiaworld.com/gaia-everworld-whitepaper/"
          target="_blank"
          rel="noreferrer"
          className="btnn prebtn centerbtn"
        >
          Whitepaper
        </a>
      </section>

      <section className="roadmap">
        <div className="container">
          <h3>roadmap</h3>

          <div className="map-list">
            <div className="map-inline-list">
              <a href className="btnn secndbtn">
                {t("2021")}
              </a>
              <div className="maprow">
                <div className="mapicon">
                  <img src={clock} alt="" />
                  <span>Q2</span>
                </div>
                <div className="dots"></div>
                <div className="map-content">
                  <p>Concept - Start of game development</p>
                </div>
              </div>
            </div>
            <div className="map-inline-list">
              <div className="maprow revecs">
                <div className="mapicon">
                  <img src={clock} alt="" />
                  <span>Q3</span>
                </div>
                <div className="dots"></div>
                <div className="map-content">
                  <p>Creation of Radicle Gaming LTD </p>
                  <p>Website Launch </p>
                  <p>Private sale </p>
                </div>
              </div>
              <div className="maprow light">
                <div className="mapicon">
                  <img src={clock} alt="" />
                  <span>Q4</span>
                </div>
                <div className="dots"></div>
                <div className="map-content">
                  <p>Gaia Elite Club Minting Event </p>
                  <p> IDO / Public Sale $GAIA </p>
                  <p> $GAIA Farming </p>
                  <p> Land Pre-Sale</p>
                </div>
              </div>
              <a href className="btnn secndbtn">
                {t("2022")}
              </a>
              <div className="maprow  revecs light">
                <div className="mapicon">
                  <img src={clock} alt="" />
                  <span>Q1</span>
                </div>
                <div className="dots"></div>
                <div className="map-content">
                  <p>New website design and Logo</p>
                  <p>BinanceNFT Partnership</p>
                  <p>Land General Sale</p>
                </div>
              </div>
              <div className="maprow light">
                <div className="mapicon">
                  <img src={clock} alt="" />
                  <span>Q2</span>
                </div>
                <div className="dots"></div>
                <div className="map-content">
                  <p> Gaia Land Marketplace</p>
                  <p>Single Asset Staking</p>
                </div>
              </div>
            </div>
            <div className="maprow  revecs light">
              <div className="mapicon">
                <img src={clock} alt="" />
                <span>Q3</span>
              </div>
              <div className="dots"></div>
              <div className="map-content">
                <p>BinanceNFT Continued </p>
              </div>
            </div>
            <div className="map-inline-list">
              <a href className="btnn secndbtn">
                {t("2023")}
              </a>
              <div className="maprow  light">
                <div className="mapicon">
                  <img src={clock} alt="" />
                  <span>Q1</span>
                </div>
                <div className="dots"></div>
                <div className="map-content">
                  <p>Land Mechanism to Burn GAIA tokens.</p>
                </div>
              </div>
              <div className="maprow revecs light">
                <div className="mapicon">
                  <img src={clock} alt="" />
                  <span>Q2</span>
                </div>
                <div className="dots"></div>
                <div className="map-content">
                  <p>Overall Integrate Chat Feature Metaverse</p>
                  <p>Launch of Ready-Player-Me Characters</p>
                </div>
              </div>
              <div className="maprow  light">
                <div className="mapicon">
                  <img src={clock} alt="" />
                  <span>Q3</span>
                </div>
                <div className="dots"></div>
                <div className="map-content">
                  <p>Launch Beta Gaia Metaverse</p>
                </div>
              </div>
            </div>
            <div className="map-inline-list">
              <div className="maprow revecs  light">
                <div className="mapicon">
                  <img src={clock} alt="" />
                  <span>Q4</span>
                </div>
                <div className="dots"></div>
                <div className="map-content">
                  <p>Game Mode Gaia Burn Mechanism</p>
                </div>
              </div>
            </div>
            <div className="map-inline-list">
              <a href className="btnn secndbtn">
                {t("2024")}
              </a>
            </div>
            <div className="map-inline-list">
              <div className="maprow   light">
                <div className="mapicon">
                  <img src={clock} alt="" />
                  <span>Q1</span>
                </div>
                <div className="dots"></div>
                <div className="map-content">
                  <p>GaiaWorld.Fund Launchpad Release</p>
                </div>
              </div>
            </div>
            <div className="map-inline-list">
              <div className="maprow revecs light">
                <div className="mapicon">
                  <img src={clock} alt="" />
                  <span>Q2</span>
                </div>
                <div className="dots"></div>
                <div className="map-content">
                  <p>Game Mode Gaia Burn Mechanism</p>
                  <p> PvE Legion Mode Alpha Release </p>
                  <p> PvP Battle Mode integration with Metaverse </p>
                  <p> Marketplace Goes Live </p>
                  <p>Play and Earn Mechanism Release</p>
                  <p> Release of Breeding Mechanism </p>
                </div>
              </div>
            </div>
            <div className="map-inline-list">
              <div className="maprow  light">
                <div className="mapicon">
                  <img src={clock} alt="" />
                  <span>Q3</span>
                </div>
                <div className="dots"></div>
                <div className="map-content">
                  <p>Relaunch and continued sale of Gaia Elite Club NFTs</p>
                  <p> Staking Mechanism for NFTs </p>
                </div>
              </div>
            </div>
            <div className="map-inline-list">
              <div className="maprow revecs light">
                <div className="mapicon">
                  <img src={clock} alt="" />
                  <span>Q4</span>
                </div>
                <div className="dots"></div>
                <div className="map-content">
                  <p>Legion Mode iOS/Android release</p>
                </div>
              </div>
            </div>
            <div className="map-inline-list">
              <a href className="btnn secndbtn">
                {t("2024 to 2028")}
              </a>
            </div>
            <div className="map-inline-list">
              <div className="maprow  light">
                <div className="mapicon"></div>
                <div className="dots"></div>
                <div className="map-content">
                  <p>
                    Continued ongoing expansion of Metaverse development of
                    Adventure Mode/MMORPG Game integration
                  </p>
                </div>
              </div>
            </div>
            <div class="maprow simpldot">
              <div class="dots"></div>
            </div>
          </div>
        </div>
      </section>
      <section className="partners">
        <div className="container">
          <h3 className="textcenter">investors and partners </h3>
          <div className="partners-logo">
            <img src={radicleg} alt="Radicle Gaming" />
            <img src={unilayer} alt="Unilayer" />
            <img src={polygon} alt="Polygon" />
            <img src={au21} alt="AU21 Capital" />
            <img src={mexc} alt="MEXCB lobal" />
            <img src={partner10} alt="Cryptobees" />
            <img src={partner9} alt="ChainGUARDS" />
            <img src={partner8} alt="AussieCAPITAL" />
            <img src={partner7} alt="defiboost" />
            <img src={partner22} alt="OIG" />
            <img src={partner18} alt="Gaia" />
            <img src={partner20} alt="Brothehood" />
            <img src={partner16} alt="Avstar CAPITAL" />
            <img src={partner14} alt="Gaia" />
            <img src={partner12} alt="Gaia" />
            <img src={partner21} alt="Gaia" />
            <img src={partner17} alt="SHIMACAPITAL" />
            <img src={partner19} alt="Gaia" />
            <img src={partner15} alt="BLACKDRAGON" />
            <img src={partner13} alt="BASICS CAPITAL" />
            <img src={partner11} alt="M6" />
            <img src={partner1} alt="THUGS CAPITAL" />
            <img src={partner2} alt="PLUTO Digital" />
            <img src={partner5} alt="Gaia" />
            <img src={partner3} alt="PANDA CAPITAL" />
            <img src={partner6} alt="Exnetwork Captial" />
            <img src={partner4} alt="Fairum" />
            <img src={partner23} alt="Fairum" />
            <img src={partner24} alt="Fairum" />
            <img src={partner25} alt="Fairum" />
            <img src={partner26} alt="Fairum" />
            <img src={partner27} alt="Fairum" />
            <img src={partner28} alt="Fairum" />
            <img src={partner29} alt="Fairum" />
            <img src={partner30} alt="Fairum" />
            <img src={partner31} alt="Fairum" />
            <img src={GaiaFund} alt="GaiaFund" />
            <img src={GOH} alt="GOH" />
            <img src={Girand} alt="Girand" />
            <img src={BBG} alt="BBG" />
            <img src={Chianplay} alt="Chianplay" />
          </div>
        </div>
      </section>
      {/* <section className="ourteam">
        <div className="container">
          <h3>our team</h3>
          <div className="teamlist">
            <div className="teammeme">
              <div className="profile">
                <img src={Brian} alt="Brian" />
              </div>
              <div className="teaminfo">
                <h3>CEO</h3>
                <p>Brian H</p>
              </div>
            </div>
            <div className="teammeme">
              <div className="profile">
                <img src={geo} alt="" />
              </div>
              <div className="teaminfo">
                <h3>Co-Founder</h3>
                <p>Geo</p>
              </div>
            </div>
            <div className="teammeme">
              <div className="profile">
                <img src={Devair} alt="" />
              </div>
              <div className="teaminfo">
                <h3>Co-Founder</h3>
                <p>Devair</p>
              </div>
            </div>
            <div className="teammeme">
              <div className="profile">
                <img src={Damir} alt="" />
              </div>
              <div className="teaminfo">
                <h3>VFX Artist</h3>
                <p>Damir Saifutdinow</p>
              </div>
            </div>
            <div className="teammeme">
              <div className="profile">
                <img src={Nicholas} alt="" />
              </div>
              <div className="teaminfo">
                <h3>Head Content Writer</h3>
                <p>Nicholas Ross</p>
              </div>
            </div>
            <div className="teammeme">
              <div className="profile">
                <img src={Dina} alt="" />
              </div>
              <div className="teaminfo">
                <h3>VFX Artist</h3>
                <p>Dina Yunita</p>
              </div>
            </div>
            <div className="teammeme">
              <div className="profile">
                <img src={Viacheslav} alt="" />
              </div>
              <div className="teaminfo">
                <h3>VFX Artist</h3>
                <p>Viacheslav Titenko</p>
              </div>
            </div>
            <div className="teammeme">
              <div className="profile">
                <img src={Ilkhom} alt="" />
              </div>
              <div className="teaminfo">
                <h3>VFX Artist</h3>
                <p>Ilkhom Razhabov</p>
              </div>
            </div>
            <div className="teammeme">
              <div className="profile">
                <img src={radicle} alt="" />
              </div>
              <div className="teaminfo">
                <h3>Radicle Gaming</h3>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
};

export default About;
